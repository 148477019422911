import { graphql } from 'gatsby'
import React from 'react'
import { Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo" 

const Realisations2Page = ({ data }) => {
 const description= [
   {name:'Comptoir Café à Hôtel Club Al Moggar', link:'/comptoir-cafe-a-hotel-club-al-moggar'},
   {name:'Comptoir à oasis hôtel & spa', link:'/comptoir-a-oasis-hotel-spa/'},
   {name:'Comptoir à Boulangerie & Café Caprice', link:'/comptoir-a-cafe-caprice/'},
   {name:'Plan de travail, Évier & Étagères à CSVTPM Agadir', link:'/plan-de-travail-etageres-evier-csvtpm-agadir/'},
   {name:'Comptoir de restauration à Café TAIBA AGADIR', link:'/comptoir-de-restauration-a-cafe-taiba-agadir/'},
   {name:'Comptoir de restauration à Caramel Agadir', link:'/comptoir-de-restauration-a-caramel-agadir/'},
   {name:'Comptoir de restauration & Vasque à Restaurant Daily’S', link:'/comptoir-de-restauration-a-restauration-dailys/'},
   {name:'Comptoir d’accueil au sein de Elite Club Spa', link:'/comptoir-sein-de-elite-club-spa/'},
   {name:'Plan de Cuisine à KITCHENA', link:'/plan-de-cuisine-a-kitchena/'},
   {name:'Banque d’accueil au Restaurant le pôle à Agadir', link:'/banque-daccueil-restaurant-pole-agadir/'},
   {name:'Comptoir d’accueil pour restaurant LA COUPOLE', link:'/comptoir-la-coupole/'},
   {name:'Buffets réfrigérés au village de surfing à Taghazout', link:'/village-de-surfing-taghazout/'},
   {name:'Comptoir d’accueil pour la boulangerie pâtisserie TAFARNOUT', link:'/comptoir-boulangerie-patisserie-tafarnout/'},
   {name:'Comptoirs d’accueil dans la pharmacie Marrakech', link:'/pharmacie-marrakech/'},
   {name:'Comptoir d’accueil pour Rosa bainca', link:'/comptoir-rosa-bainca/'},
   {name:'Tables extérieures pour Hôtel Sofitel', link:'/tables-exterieures-hotel-sofitel/'},
   {name:'Plan de travail & Vasque pour la clinique dentaire à Agadir', link:'/plan-de-travail-vasque-clinique-agadir/'},
   {name:'Comptoir & vasque au sein d’AmadL', link:'/amadel-comptoir-vasque/'},
 ];
  return(
      <Layout>
        <SEO title="Nos réalisations" /> 
        <div class="grid grid-cols-1 mt-8 md:mx-40 md:my-5 mx-3">
                <div class=" md:gap-3 realisations project">
                    { data.allFile.edges.map(({node}, i) =>  ( 
                      <div class="md:w-1/3 md:float-left p-3">
                        <div class="relative">
                            <Link to={ `/projects`+description[parseInt(node.name)-1].link }><p></p> 
                              <Img class="rounded" fluid={node.childImageSharp.full} />
                              <span key={i} class="absolute bottom-12 left-0 right-0 px-3 py-2 bg-gray-700 font-bold text-white">
                                {description[parseInt(node.name)-1].name}
                              </span>
                            </Link>
                        </div>
                      </div>
                    )
                    )} 
                </div> 
        </div>
      </Layout>
)
}
export const query = graphql`
query Realisations2 {
     allFile (filter:{relativeDirectory: {eq: "img/realisations-2"}}){
      edges {
        node {
          name
          childImageSharp {
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
}
`
export default Realisations2Page